$(document).ready(function() {
    
    $('.header__burger').click(function() {
        $(this).toggleClass('open');
        if($('header nav').is(':visible')) {
            $('header nav').slideUp();
        } else {
            $('header nav').slideDown();
        }
        if($(window).width() <= 980) {
            $('header nav > ul:first-of-type > li').on('click', '> a', function(e) {
                console.log('Clicked link!');
                e.preventDefault();
                $(this).siblings('ul').slideToggle();
            });
        }
	});
    
    if($('.home__content').length) {
        
        const grid = document.querySelector(".home__content");
        if($(window).width() >= 980) {
            $('.home__content').on('click', '.home__content--block', function(e) {
                e.preventDefault();        
                $(this).toggleClass('open'); 
                $('.home__content--block').on('click', 'span[data-href]', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    window.location = $(this).data('href');
                });
            });
        }
        animateCSSGrid.wrapGrid(grid, { duration: 500 });
        
    }
    
    // Sticky sub menu
    if($('nav.menu').length) {
        
        var menuTop = $('nav.menu').position().top,
            offset  = $('body').hasClass('customize-support') ? 162 : 130;
        
        if($(window).width() <= 980) {
            offset += 10;    
        }
        
        var height  =  menuTop - offset;

        $(window).on('scroll', function() {

            if($(window).scrollTop() >= height) {
                $('nav.menu').addClass("sticky");
            } else {
                $('nav.menu').removeClass("sticky");
            }

        });
        
    }
    
    $('.marina__berthing').on('change', 'select', function() {
        var band = $(this).find('option:selected').val();
        $('.marina__berthing div[data-band]').hide();
        $('.marina__berthing div[data-band='+band+']').css('display', 'flex');        
    });
    
});